//@ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { batch, connect, useSelector } from 'react-redux';
import printJS from 'print-js';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Viewer, { Worker } from '@ocr-gateway/react-pdf-viewer';

import {
  Button,
  ExitPdfWarningPopup,
  Icon,
  TranslatePopup,
  toast,
  TryItNowImages,
  GenericPopup,
  Typography,
  CustomCheckbox
} from '@ocr-gateway/ui';
import '@ocr-gateway/react-pdf-viewer/dist/index.css';
import * as currentDocumentActions from '@ocr-gateway/shared';
import { changeCurrentTip, changeShowTips, dontShowTipsAgain } from '@ocr-gateway/shared';
import {
  LanguageSection,
  TranslateContainer,
  TranslateInfoHeader,
  TranslateModeTag,
  IconSection,
  PdfViewerHeader,
  CustomHeaderButton,
  Line,
  ButtonSection,
  GreyBackground
} from './styles';

import {
  resetReprocessedIsSet,
  setPredefinedDateFormats,
  setNerClasses,
  setReprocessRequest,
  setSimpleExtraction,
  Api,
  convertUint8ArrayToPdfBase64,
  encodeUintArray,
  setGetStartedPopup,
  setPdfQualifierWarningPopup,
  setPdfWarningPopup,
  setTranslatePopup,
  setHubWithoutQualifier,
  API_URL,
  navbarHeight,
  AppState,
  resetHubsState,
  clearOutline
} from '@ocr-gateway/shared';

import * as smartTagsActions from '@ocr-gateway/shared';

import { useAuthHeader } from '../../hooks/useAuthHeader';

interface PDFDocumentProxy {
  getData: () => Promise<Uint8Array>;
}

export const DocumentViewComponent = ({
  outline,
  exportedCsv,
  dispatch,
  // isSimpleExtraction,
  isComposerBotExtraction,
  predefinedDateFormats,
  hub,
  reprocessRequest,
  pdfWarningPopup,
  replacedCaptureNow,
  translatePopup,
  pdfQualifierWarningPopup,
  hubWithoutQualifier,
  reprocessedOutline,
  currentDocument,
  withSmartCapture
}: any) => {
  const location = useLocation();
  const { foundField } = useSelector((state: any) => state.semantic);
  const [showWelcome, setShowWelcome] = useState(false);
  const [dontShowAgainTips, setDontShowAgainTips] = useState(false);
  const [selectedChangeDocumentOrientation, setSelectedChangeDocumentOrientation] = useState(false);
  const [dontShowWelcomeFlow, setDontShowWelcomeFlow] = useState(false);
  const statePopupTranslate = useSelector((state: AppState) => state.translatePopup);
  const { showTips, dontShowAgain } = useSelector((state: AppState) => state.tryitNowReducer);
  const { organization, roles, email } = useSelector((state: any) => state.auth.user);
  const isHubLimited =
    organization.managedDataCuration && !roles.includes('SUPPORT') && hub.owner !== email;
  const [translatedDocument, setTranslatedDocument] = useState(null);
  const [isTranslationMode, setIsTranslationMode] = useState(false);
  const mustSetQualifierWarningRef = useRef<any>(null);
  const [error, setError] = useState<any>('');
  const httpHeaders = useAuthHeader();
  const { id: hubId, outlineId } = useParams<any>();
  const [failedTryItNow, setFailedTryItNow] = useState(false);
  const [currentTranslateOptions, setCurrentTranslateOptions] = useState({
    documentId: '',
    targetLanguage: '',
    targetName: '',
    targetFormat: 'PDF',
    languageName: ''
  });
  const [documentLanguage, setDocumentLanguage] = useState('English');
  const isSimpleExtraction = hubId === 'simple' ? true : false;

  const { documentId } = outline;
  const [fileUrl, setFileUrl] = useState(null);
  /* const fileUrl = `${API_URL}/${
    isComposerBotExtraction ? 'document-resource' : 'documents'
  }/${documentId}${isComposerBotExtraction ? '' : '?type=PROCESSED'}`; */

  const fetchNerClasses = async () => {
    const classes = await Api.getNerClasses();

    dispatch(setNerClasses(classes));
  };

  useEffect(() => {
    const outlineResponse = JSON.parse(sessionStorage.getItem('outlineResponse'));
    if (documentId || (outlineResponse && outlineResponse.documentId)) {
      let tempFileUrl = `${API_URL}/${
        isComposerBotExtraction ? 'document-resource' : 'documents'
      }/${outlineResponse ? outlineResponse.documentId : documentId}${
        isComposerBotExtraction ? '' : '?type=PROCESSED'
      }`;
      setFileUrl(tempFileUrl);
    }
  }, [outlineId, hubId, documentId]);
  useEffect(() => {
    if (isSimpleExtraction) {
      dispatch(resetHubsState());
    }
  }, []);

  useEffect(() => {
    const fetchOutlineAndMetadata = async () => {
      try {
        const isReprocessedSet = sessionStorage.getItem('reprocessedIsSet');
        if (!isSimpleExtraction && !isReprocessedSet) {
          if (outlineId) {
            await Api.getOutline({ dispatch, outlineId, isSimpleExtraction });
            if (documentId) {
              const data = await Api.getDocumentDetails({ documentId });
              setDocumentLanguage(data.documentLanguage?.name);
            }
          }
        } else {
          if (outlineId) {
            await Api.getOutLineTryItNow({ dispatch, outlineId, isSimpleExtraction });
            if (documentId) {
              const data = await Api.getDocumentDetails({ documentId });
              setDocumentLanguage(data.documentLanguage?.name);
            }
          }
        }
      } catch (e) {
        setFailedTryItNow(true);
        console.log({ e });
      }
    };
    fetchOutlineAndMetadata();
  }, [outlineId, documentId]);

  useEffect(() => {
    if (!Object.keys(predefinedDateFormats).length) {
      Api.getPredefinedDateFormats().then(formats => dispatch(setPredefinedDateFormats(formats)));
    }
  }, [predefinedDateFormats]);

  useEffect(() => {
    fetchNerClasses();
  }, []);

  const actions = useMemo(
    () => ({
      ...smartTagsActions,
      ...currentDocumentActions
    }),
    []
  );

  const catchAsyncGetFullInfoForOutline = async (cb: () => Promise<void>) => {
    try {
      await cb();
      if (error) {
        setError('');
      }
    } catch (e) {
      console.log({ e });
      setError('An error occurred. File not found.');
    }
  };

  useEffect(() => {
    catchAsyncGetFullInfoForOutline(async () => {
      if (isSimpleExtraction) {
        /* await Api.getHub({
              id: hubId,
              dispatch,
              config: { include: ['outline', 'channels'] }
            }); */
        await Api.getOutLineTryItNow({
          dispatch,
          outlineId: outlineId,
          isSimpleExtraction
        });
      }
    });
  }, [, isSimpleExtraction, hubId, outlineId]);

  useEffect(() => {
    catchAsyncGetFullInfoForOutline(async () => {
      if (!isSimpleExtraction && !isComposerBotExtraction) {
        if (!location?.state?.fromHub) {
          await Api.getHub({
            id: hubId,
            dispatch,
            config: { include: ['short_outline', 'channels'] }
          });
          await Api.getOutline({ dispatch, outlineId: outlineId });
        } else {
          await Api.getOutline({ dispatch, outlineId: outlineId });
          window.history.replaceState({}, '');
        }
      }
    });
  }, [hubId, isSimpleExtraction]);

  const [pdfFile, setPdfFile] = useState<null | string>(null);

  const handleClosePdfWarning = (e: React.MouseEvent) => {
    dispatch(setPdfWarningPopup({ show: false, onGetStartedClick: false }));
  };
  const handleCloseTranslate = (e: React.MouseEvent) => {
    dispatch(setTranslatePopup({ show: false }));
  };

  const history = useHistory();

  const handleWarningClickBtn1 = async (e: React.MouseEvent | any) => {
    if (pdfWarningPopup.onGetStartedClick) {
      batch(() => {
        dispatch(setPdfWarningPopup({ show: false, goTo: `${pdfWarningPopup.goTo}#force` }));
        dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
      });
    } else {
      dispatch(
        setPdfWarningPopup({
          show: false,
          onGetStartedClick: false,
          goTo: `${pdfWarningPopup.goTo}#force`
        })
      );
      history.push(`${pdfWarningPopup.goTo}#force`);
    }
    sessionStorage.removeItem('reprocessedIsSet');
  };

  const handleWarningClickBtn2 = async (e: React.MouseEvent) => {
    await Api.exportOutline({ dispatch, outlineId: outline.id });
    await handleWarningClickBtn1(e);
    sessionStorage.removeItem('reprocessedIsSet');
  };

  const handleTranslateClickBtn1 = async (e: React.MouseEvent | any) => {
    dispatch(
      setTranslatePopup({
        show: false,
        isFormCompleted: false,
        formValues: {
          documentId: '',
          targetLanguage: '',
          targetName: '',
          targetFormat: ''
        }
      })
    );
  };
  const handleTranslatedocument = () => {
    dispatch(
      setTranslatePopup({
        ...statePopupTranslate,
        show: true
      })
    );
  };

  const dataToBeCleaned = useRef(null);

  useEffect(() => {
    dataToBeCleaned.current = {
      reprocessRequest,
      reprocessedOutlineId: reprocessedOutline.id
    };
  }, [reprocessRequest, reprocessedOutline]);

  useEffect(() => {
    return () => {
      dispatch(setSimpleExtraction(false));
      if (hubId === 'simple') {
        const reprocessRequestData = dataToBeCleaned.current.reprocessRequest;
        if (reprocessRequestData) {
          reprocessRequestData.cancel();
          dispatch(setReprocessRequest(null));
        }
        Api.deleteOutlineTryItNow({
          dispatch,
          outlineId: outlineId,
          clearState: true,
          stateKey: ['outline', 'simpleOutline']
        });
        const reprocessedId =
          dataToBeCleaned.current && dataToBeCleaned.current.reprocessedOutlineId;
        if (reprocessedId) {
          Api.deleteOutlineTryItNow({
            dispatch,
            outlineId: outlineId,
            clearState: true,
            stateKey: 'reprocessedOutline'
          });
        }
        dispatch(resetReprocessedIsSet());
      }
    };
  }, []);

  const isOnGetStartedClick = (
    isClicked: boolean,
    actionCreator: ({ onGetStartedClick: boolean }) => void
  ) => {
    if (isClicked) {
      dispatch(actionCreator({ onGetStartedClick: false }));
    }
    return isClicked;
  };

  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    if (isSimpleExtraction) window.addEventListener('beforeunload', unloadCallback);
    return () => {
      //window.addEventListener('popstate', confirmation());
      if (isSimpleExtraction) window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [isSimpleExtraction]);

  useEffect(() => {
    if (!failedTryItNow) {
      const unblock = history.block(location => {
        const { pathname, hash, state } = location;
        const isForce = hash === '#force';
        if (!isSimpleExtraction || exportedCsv || isForce || error) {
          if (state && (state as any).isHubCancelling) return;
          // this 2 if conditions are needed if you go to hub creation to try it now and vice verse
          const [isClickedWarning, isClickedQualifier] = [
            isOnGetStartedClick(pdfWarningPopup.onGetStartedClick, setPdfWarningPopup),
            isOnGetStartedClick(
              pdfQualifierWarningPopup.onGetStartedClick,
              setPdfQualifierWarningPopup
            )
          ];
          if (isClickedWarning || isClickedQualifier) return;
          if (mustSetQualifierWarningRef.current && hub && !isForce && !hub.locked) {
            dispatch(
              setPdfQualifierWarningPopup({
                show: true,
                goTo: pathname,
                onGetStartedClick: false
              })
            );
            return false;
          }
          return;
        }
        // this if condition is needed if you go to hub creation to try it now and vice verse
        const isClicked = isOnGetStartedClick(
          pdfQualifierWarningPopup.onGetStartedClick,
          setPdfQualifierWarningPopup
        );
        if (isClicked || exportedCsv) return;
        if (outline && outline.fields && outline.fields.length) {
          dispatch(
            setPdfWarningPopup({
              show: !pdfWarningPopup.onGetStartedClick,
              goTo: pathname,
              onGetStartedClick: false
            })
          );
        }
        if (isSimpleExtraction && !failedTryItNow) {
          if (window.confirm('Are you sure you want to leave this page?')) {
            unblock();
            return;
          }
        }
        return false;
      });
      return () => {
        unblock();
      };
    } else {
      history.push('/');
    }
  }, [
    exportedCsv,
    failedTryItNow,
    isSimpleExtraction,
    error,
    pdfWarningPopup,
    pdfQualifierWarningPopup,
    hub,
    outline,
    outline.fields,
    outline.fields?.length
  ]);

  const handleClosePdfQualifierWarning = (e: React.MouseEvent) => {
    dispatch(
      setPdfQualifierWarningPopup({
        onGetStartedClick: false,
        show: false
      })
    );
  };

  const handleQualifierWarningClickBtn1 = async (e: React.MouseEvent | any) => {
    const pdfQualifierWarningPopupData = {
      show: false,
      goTo: `${pdfQualifierWarningPopup.goTo}#force`
    };
    if (pdfQualifierWarningPopup.onGetStartedClick) {
      batch(() => {
        dispatch(setPdfQualifierWarningPopup(pdfQualifierWarningPopupData));
        dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
      });
    } else {
      dispatch(
        setPdfQualifierWarningPopup({ onGetStartedClick: false, ...pdfQualifierWarningPopupData })
      );
      history.push(`${pdfQualifierWarningPopup.goTo}#force`);
    }
  };

  useEffect(() => {
    if (outline.id) {
      const hasAtLeastOneQualifier = outline.fields?.some(({ isQualifier }: any) => isQualifier);
      const mustSetQualifierWarning =
        !hasAtLeastOneQualifier && !withSmartCapture && !isSimpleExtraction && hub && !hub.smartHub;
      mustSetQualifierWarningRef.current = mustSetQualifierWarning;
    }
  }, [outline, isSimpleExtraction, hub]);

  useEffect(() => {
    if (mustSetQualifierWarningRef.current !== null && !isSimpleExtraction) {
      if (mustSetQualifierWarningRef.current && hub && !hub.locked) {
        !hubWithoutQualifier && dispatch(setHubWithoutQualifier(true));
      } else {
        hubWithoutQualifier && dispatch(setHubWithoutQualifier(false));
      }
    }
  }, [mustSetQualifierWarningRef.current, outline, isSimpleExtraction]);

  // TODO: move this into pdf data preview library
  const onOpenFile = (data: PDFDocumentProxy) => {
    data.getData().then(array => {
      convertUint8ArrayToPdfBase64(array, setPdfFile);
    });
  };

  const handlePrintClick = (pdf: PDFDocumentProxy) => {
    pdf &&
      pdf.getData().then((byteArray: Uint8Array) =>
        printJS({
          printable: encodeUintArray(byteArray),
          type: 'pdf',
          base64: true,
          showModal: true
        })
      );
  };

  const handleTranslate = async formOptions => {
    var reader = new FileReader();
    setCurrentTranslateOptions(formOptions);
    const data = await Api.translateDocument(formOptions);
    const file = window.URL.createObjectURL(new Blob([data]));
    reader.onload = () => {
      console.log(reader.result);
    };
    reader.readAsArrayBuffer(data);
    setTranslatedDocument(file);
    setIsTranslationMode(true);
  };

  const handleLimitExceeded = async () => {
    return await Api.isTranslateDocumentLimitExceeded();
  };

  const downloadDocument = async () => {
    try {
      await Api.handlePDFDownload(outline, fileUrl);
    } catch (e) {
      toast.error('An error occurred while downloading pdf', { autoClose: 500 });
    }
  };
  const downloadTranslatedDocument = async () => {
    try {
      await Api.translateDocument({
        ...currentTranslateOptions,
        shouldDownload: true
      });
    } catch (e) {
      toast.error('An error occurred while downloading pdf', { autoClose: 500 });
    }
  };
  useEffect(() => {
    if (isSimpleExtraction) {
      setShowWelcome(true);
      dispatch(changeShowTips(true));
    }
  }, [isSimpleExtraction]);
  useEffect(() => {
    const tips = JSON.parse(localStorage.getItem('dontShowTips'));
    if (tips) {
      setDontShowWelcomeFlow(tips);
      dispatch(dontShowTipsAgain(tips));
    }
  }, []);
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div>
        {fileUrl && (
          <div
            className="container"
            style={{
              minHeight: '850px',
              height: `calc(100vh - ${navbarHeight})`,
              width: '100%',
              margin: '0 auto',
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            {showTips && isSimpleExtraction && !dontShowWelcomeFlow && <GreyBackground />}
            {documentId && isTranslationMode ? (
              <>
                <PdfViewerHeader>
                  <CustomHeaderButton
                    className="translate"
                    id="open-translate-modal"
                    onClick={handleTranslatedocument}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Icon name="google-translate" style={{ margin: '0' }} />
                      <span style={{ margin: '0 7px' }}>Translate Document</span>
                    </div>
                  </CustomHeaderButton>
                  <Line></Line>
                  <CustomHeaderButton
                    onClick={() => history.push(`/#force`)}
                    id="done"
                    className="toolbar-pdf-fixed"
                  >
                    <Icon name="toolbar-exit" />
                    Exit
                  </CustomHeaderButton>
                </PdfViewerHeader>
                <TranslateInfoHeader>
                  <div style={{ width: '270px', opacity: 0 }}>ssd</div>
                  <LanguageSection>
                    <span style={{ width: '143px', textAlign: 'end' }}>
                      {!selectedChangeDocumentOrientation
                        ? documentLanguage
                        : currentTranslateOptions.languageName}
                    </span>
                    <IconSection
                      isSelected={selectedChangeDocumentOrientation}
                      onClick={() => setSelectedChangeDocumentOrientation(prev => !prev)}
                    >
                      <Icon name="arrow-right" color="black" />
                      <Icon name="arrow-left" color="black" />
                    </IconSection>
                    <span style={{ width: '143px' }}>
                      {!selectedChangeDocumentOrientation
                        ? currentTranslateOptions.languageName
                        : documentLanguage}
                    </span>
                  </LanguageSection>
                  <Button
                    textCenter
                    textUppercase
                    transparent
                    onClick={() => setIsTranslationMode(false)}
                    style={{ marginRight: '20px' }}
                  >
                    exit translate mode
                  </Button>
                </TranslateInfoHeader>
                <TranslateContainer>
                  <TranslateModeTag>translate mode</TranslateModeTag>
                  <Viewer
                    foundSemanticField={foundField}
                    isSimpleExtraction={isSimpleExtraction}
                    fileUrl={!selectedChangeDocumentOrientation ? fileUrl : translatedDocument}
                    fileName={outline ? outline.name : ''}
                    httpHeaders={httpHeaders}
                    initialPage={0}
                    actions={actions}
                    // sidebarOpened
                    Api={Api}
                    history={history}
                    downloadDocument={downloadDocument}
                    onDocumentLoad={onOpenFile}
                    onPrint={handlePrintClick}
                    documentEditDisabled={(hub && hub.locked) || isHubLimited}
                    isHubSmart={!!hub?.smartHub}
                    //translatedDoc={translatedDocument}
                    hideToolbar
                    customBackground="#EAEAEA"
                    removeSidebars={true}
                  />
                  <Viewer
                    foundSemanticField={foundField}
                    isSimpleExtraction={isSimpleExtraction}
                    fileUrl={!selectedChangeDocumentOrientation ? translatedDocument : fileUrl}
                    fileName={outline ? outline.name : ''}
                    httpHeaders={httpHeaders}
                    initialPage={0}
                    actions={actions}
                    // sidebarOpened
                    Api={Api}
                    history={history}
                    downloadDocument={downloadTranslatedDocument}
                    onDocumentLoad={onOpenFile}
                    onPrint={handlePrintClick}
                    documentEditDisabled={(hub && hub.locked) || isHubLimited}
                    isHubSmart={!!hub?.smartHub}
                    //translatedDoc={translatedDocument}
                    hideToolbar
                    removeSidebars={true}
                  />
                </TranslateContainer>
              </>
            ) : (
              <Viewer
                foundSemanticField={foundField}
                fileUrl={fileUrl}
                isSimpleExtraction={isSimpleExtraction}
                fileName={outline ? outline.name : ''}
                httpHeaders={httpHeaders}
                initialPage={0}
                actions={actions}
                sidebarOpened
                Api={Api}
                history={history}
                downloadDocument={downloadDocument}
                onDocumentLoad={onOpenFile}
                onPrint={handlePrintClick}
                documentEditDisabled={(hub && hub.locked) || isHubLimited}
                isHubSmart={!!hub?.smartHub}
                //translatedDoc={translatedDocument}
              />
            )}
            {error && (
              <div
                style={{
                  height: `calc(100vh - ${navbarHeight})`,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'none'
                }}
              >
                <Icon name="file-error" />
                <span
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    marginTop: '23px',
                    marginBottom: '14px'
                  }}
                >
                  Oops...
                </span>
                <span
                  style={{
                    marginBottom: '25px'
                  }}
                >
                  {error}
                </span>
                <Button
                  textCenter
                  textUppercase
                  style={{ width: '119px', height: '50px' }}
                  onClick={history.goBack}
                >
                  ok
                </Button>
              </div>
            )}
            <ExitPdfWarningPopup
              fixed
              show={pdfQualifierWarningPopup.show}
              header="Oops! You forgot to add a qualifier."
              bnt1text="Keep hub inactive"
              bnt2text="Add a qualifier"
              text="Qualifiers tell OCR Gateway which future documents will fit this hub. If documents using this hub will always contain a certain field (such as a title), you can use that field as a qualifier. To make this hub active, please add a qualifier."
              onClose={handleClosePdfQualifierWarning}
              onBtn2Click={handleClosePdfQualifierWarning}
              onBtn1Click={handleQualifierWarningClickBtn1}
            />
            <ExitPdfWarningPopup
              fixed
              show={pdfWarningPopup.show}
              header="Are you sure you want to exit without exporting?"
              bnt1text="Exit without exporting"
              bnt2text="Export and Exit"
              text={`Your document will not save if you exit now, and your data will be lost. If you'd like to see your data, click on Export results in the top right.`}
              onClose={handleClosePdfWarning}
              onBtn1Click={handleWarningClickBtn1}
              btn2Disabled={!(outline && outline.fields && outline.fields.length)}
              onBtn2Click={handleWarningClickBtn2}
            />
            <TranslatePopup
              fixed
              show={translatePopup.show}
              header="Translate Document"
              bnt1text="Cancel"
              bnt2text="Translate"
              text="Create a translated copy of the current document"
              onClose={handleCloseTranslate}
              onBtn1Click={handleTranslateClickBtn1}
              btn2Disabled={!translatePopup.isFormCompleted}
              handleLimitExceeded={handleLimitExceeded}
              handleTranslate={handleTranslate}
            />
            <GenericPopup
              show={showWelcome && isSimpleExtraction && !dontShowWelcomeFlow}
              onClose={() => {
                dispatch(changeCurrentTip(0));
                dispatch(changeShowTips(false));
                localStorage.setItem('dontShowTips', JSON.stringify(dontShowAgainTips));
                setShowWelcome(false);
              }}
              style={{ width: '622px', padding: '0' }}
            >
              <TryItNowImages image="welcome-image" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '250px',
                  justifyContent: 'space-evenly'
                }}
              >
                <Typography type="h1" style={{ textAlign: 'center' }}>
                  Welcome to OCR Gateway
                </Typography>
                <Typography
                  type="p"
                  style={{ textAlign: 'center', marginLeft: '30px', marginRight: '10px' }}
                >
                  OCR Gateway will help you become a more present and engaging manager, as well as
                  support you in managing challenges in your work environment
                </Typography>
                <ButtonSection>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CustomCheckbox
                      checked={dontShowAgainTips}
                      onChange={e => {
                        dispatch(dontShowTipsAgain(!dontShowAgainTips));
                        setDontShowAgainTips(prev => !prev);
                      }}
                    />
                    <Typography type="span">Don't show again</Typography>
                  </div>
                  <Button
                    textCenter
                    textUppercase
                    style={{ width: '160px', height: '50px' }}
                    onClick={() => {
                      dispatch(changeCurrentTip(1));
                      setShowWelcome(false);
                    }}
                  >
                    let's start
                  </Button>
                </ButtonSection>
              </div>
            </GenericPopup>
          </div>
        )}
      </div>
    </Worker>
  );
};

function mapStateToProps(state: any) {
  return {
    outline: state.currentDocument.outline,
    predefinedDateFormats: state.currentDocument.predefinedDateFormats,
    isSimpleExtraction: state.currentDocument.isSimpleExtraction,
    isComposerBotExtraction: state.currentDocument.isComposerBotExtraction,
    exportedCsv: state.currentDocument.exportedCsv,
    hub: state.hubs.currentHub,
    replacedOutline: state.currentDocument.replacedOutline,
    reprocessRequest: state.currentDocument.reprocessRequest,
    reprocessedOutline: state.currentDocument.reprocessedOutline,
    pdfWarningPopup: state.popups.pdfWarningPopup,
    replacedCaptureNow: state.currentDocument.replacedCaptureNow,
    translatePopup: state.popups.translatePopup,
    pdfQualifierWarningPopup: state.popups.pdfQualifierWarningPopup,
    hubWithoutQualifier: state.hubs.hubWithoutQualifier,
    currentDocument: state.currentDocument,
    withSmartCapture: state.popups.uploadDocForSmartCapture
  };
}

export const DocumentView = connect(mapStateToProps)(DocumentViewComponent);
