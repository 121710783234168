import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { Global } from '@emotion/core';

import {
  configureStore,
  bootstrapAuth,
  parseQueryParams,
  getGtmConfig,
  Api
} from '@ocr-gateway/shared';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import TagManager from 'react-gtm-module';

import { globalStyles } from './styles';
import App from './App';
import '@ocr-gateway/ui/dist/index.css';
import { instance } from './matomo.instance';

// import Cookies from 'js-cookie'
// import axios from 'axios'

const store = configureStore();
const queryClient = new QueryClient();
const { jigrod: organizationId } = parseQueryParams(window.location.search, ['jigrod']);
const gtmConfig = getGtmConfig();

bootstrapAuth({ dispatch: store.dispatch, organizationId });
Api.getNotifications({ dispatch: store.dispatch });

TagManager.initialize(gtmConfig);

ReactDOM.render(
  <>
    <Global styles={globalStyles} />
    <MatomoProvider value={instance}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </Provider>
      </QueryClientProvider>
    </MatomoProvider>
  </>,
  document.getElementById('root')
);
